import omitBy from 'lodash/omitBy'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { DICTIONARIES } from '@/constants'
import type { ApiResponse, LibraryItem, PaginatedResponse } from '~/types'
import { useLibrariesStore } from '@/store/libraries'
import type { ExclusiveUnit, DownloadOffer, UnitPaymentToken, PropertyTag } from '~/types/properties'
import { useAuthStore } from '~/store/auth'

const getKeyByValue = (value: string): string | undefined => {
  const entries = Object.entries(DICTIONARIES)
  for (const [key, val] of entries) {
    if (val === value) return key
  }
  return undefined
}

export const useGetProperties = async (params: Record<string, any>) => {
  const query = omitBy(toRaw(params), (f) => f === null || f === undefined)

  return await useAPIPropertiesFetch<ApiResponse<any> & { total: number }>(`external/residentials`, {
    query,
  })
}

export const useGetPropertiesTags = async (query?: { only_attached_to_properties: number }) => {
  return await useAPIPropertiesFetch<ApiResponse<PropertyTag[]>>(`tags`, {
    query,
  })
}

export const useGetMapProperties = async (params: Record<string, any>) => {
  const query = omitBy(toRaw(params), (f) => f === null || f === undefined)

  return await useAPIPropertiesFetch(`residentials/map`, {
    query,
  })
}

export const useGetProperty = async (id: string) => {
  return await useAPIPropertiesFetch(`residentials/${id}`)
}

export const useGetExternalProperty = async (id: string) => {
  return await useAPIPropertiesFetch(`external/residentials/${id}`)
}

export const useGetPropertyDeveloper = async (id: string) => {
  const { data, error } = await useAPIPropertiesFetch(`external/property/${id}/developer`)

  if (error.value) throw createError(error.value)

  return data.value
}

export const useDownloadBrochures = async (id: string) => {
  const { data, error } = await useAPIPropertiesFetch(`external/properties/${id}/download-brochures`)

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetPropertiesLabels = async () => {
  const { data, error } = await useAPIPropertiesFetch(`tags`)

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetPropertiesNames = (query: { exclusives_only: number }) => {
  return useAPIPropertiesFetch<ApiResponse<LibraryItem[]>>(`external/properties/names`, {
    method: 'GET',
    query,
  })
}

// flexible composable to fetch any library
export const useGetPropertiesLibrary = async (libraryName: DICTIONARIES): Promise<LibraryItem[]> => {
  const libraryKey = getKeyByValue(libraryName)

  const librariesStore = useLibrariesStore()
  // Convert libraryName to Start Case and then to Camel Case
  const camelCaseName = camelCase(libraryKey)
  const getterName = `get${upperFirst(camelCaseName)}`
  const setterName = `set${upperFirst(camelCaseName)}`
  if (librariesStore[getterName]?.length) {
    return librariesStore[getterName]
  } else {
    try {
      const { data } = await useAPIPropertiesFetch(`dictionary/${libraryName}`)

      const library = (data.value.data as LibraryItem[]) || []
      const storeSetter = librariesStore[setterName as keyof typeof librariesStore]
      if (!storeSetter) {
        throw createError(`Library ${libraryName} not found in store`)
      } else {
        storeSetter(library)
        return library
      }
    } catch (error: any) {
      throw createError(error)
    }
  }
}

export const useCreatePropertyOffer = async (body: any) => {
  const { data, error } = await useAPIFetch('/property-catalogue/offers', {
    method: 'post',
    body,
  })
  if (error.value) throw createError(error.value)

  return (data.value as any).data
}

export const useGetListPropertyOffer = async (id: number) => {
  const { data, error } = await useAPIFetch(`/leads/${id}/offers`)
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useGetPublicPropertyOffer = async (hash: string) => {
  const config = useRuntimeConfig()
  const { data } = await $fetch(`${config.public.API_URL}property-catalogue/public/offers/${hash}`)
  return data
}

export const useGetExclusiveUnits = async (query: Record<string, any>): Promise<PaginatedResponse<ExclusiveUnit[]>> => {
  const { data, error } = await useAPIPropertiesFetch<PaginatedResponse<ExclusiveUnit[]>>(
    `external/properties/exclusive-units`,
    {
      query,
    }
  )

  if (error.value) throw createError(error.value)

  return data.value
}

export const exportSalesOffer = async (propertyId: number, unitId: number, body: DownloadOffer) => {
  const config = useRuntimeConfig()

  const authStore = useAuthStore()
  const headers = {}
  if (authStore.getUserCurrencyCode) {
    headers['x-thrive-currency-code'] = authStore.getUserCurrencyCode
  }
  if (authStore.getUserMeasureId) {
    headers['x-thrive-measure-id'] = authStore.getUserMeasureId
  }

  return await new Promise((resolve, reject) => {
    useAPIFetch(`properties/${propertyId}/sales-offers/units/${unitId}`, {
      baseURL: config.public.API_URL_PROPERTY_CATALOGUE,
      method: 'post',
      headers,
      body,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}

export const useCreatePaymentLink = async (body: any) => {
  const { data, error } = await useAPIFetch('/payment-token', {
    method: 'post',
    body,
  })
  if (error.value) throw createError(error.value)

  return (data.value as any).data
}

export const useVerifyPaymentLink = async (paymentTokenId: number) => {
  const { data, error } = await useAPIFetch(`payment-token/${paymentTokenId}/verify`, {
    method: 'post',
  })
  if (error.value) throw createError(error.value)

  return (data.value as any).data
}

export const useGetPaymentTokens = async (
  query: Record<string, any>
): Promise<
  PaginatedResponse<UnitPaymentToken[]> & {
    meta: {
      on_hold_total: number
    }
  }
> => {
  const { data, error } = await useAPIFetch<PaginatedResponse<UnitPaymentToken[]>>(`payment-tokens`, {
    query,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useCancelPaymentToken = async (
  id: UnitPaymentToken['id']
): Promise<PaginatedResponse<UnitPaymentToken[]>> => {
  const { data, error } = await useAPIFetch<PaginatedResponse<UnitPaymentToken[]>>(`payment-token/${id}/cancel`, {
    method: 'POST',
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetPropertySearch = async (query: string) => {
  const { data, error } = await useAPIPropertiesFetch(`search/autocomplete?query=${query}`)

  if (error.value) throw createError(error.value)
  return data.value
}
